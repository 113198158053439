// import { createApp } from 'vue'
// import App from './App.vue'
// createApp(App).mount('#app')

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta';

const app = createApp(App)

app.use(router)
app.use(VueMeta);
app.mount('#app')

